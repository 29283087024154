<template>
  <div style="background-color: #E6E8EA;" class="py-8">
    <v-row style="max-width: 1200px; margin-left: auto;margin-right: auto;">
      <v-col cols="12" md="8">
        <v-card max-width="800px"  class="mx-auto" flat>
          <v-img height="170px" src="/static/pending-bg.jpeg">
          </v-img>
          <v-card-title class="pt-0 flex-column flex-md-row" style="flex-wrap: nowrap;">
            <v-avatar style=" top: -32px; border-width: 3px; border-style: solid; border-color: white;" size="128" class="elevation-0 flex-grow-0 flex-shrink-0" >
              <v-img style="" :src="!!employeeData.avatar? employeeData.avatar.includes('http')?employeeData.avatar: +$baseURL +employeeData.avatar : '/static/icons/avatar_holder.png'"></v-img> 
            </v-avatar>
            <div class="flex-grow-0 flex-shrink-1 mx-3" style="width: 100%">
              <span class="text-title font-weight-bold px-0" style="word-wrap: ;">{{employeeData.employeeName}}</span>
              <v-card-subtitle class="subtitle-1 pt-0 px-0 green--text text--darken-3"> <v-icon left small color="green">mdi-briefcase</v-icon>{{employeeData.positionStateName}}</v-card-subtitle>
              <v-card-subtitle class="text-caption pt-0 px-0" style="word-break: break-word; ">
                <v-icon left small color="primary">mdi-chevron-right</v-icon>Nhân viên kiểm tra lại thông tin và liên hệ bộ phần HR cập nhật lại trước ngày nhận việc nếu có sai sót
              </v-card-subtitle>
            </div>
          </v-card-title>
          <v-card-text style="min-height: 50vh;" class="text--primary ">
          <h3 class="text-h5">Thông tin công việc</h3>
          <v-divider class="my-3"></v-divider>
          <v-row class="my-0">
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Thông tin nhận việc</span>
              <p class="text-caption text--secondary"> Công việc và nơi nhận công tác</p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.departmentName" label="Công tác tại"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.departmentAddress" label="Địa chỉ công tác"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.positionStateName" label="Chức danh"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="formatDate(employeeData.hireDate)" label="Ngày nhận việc vào"  background-color="orange lighten-4" class="font-weight-bold"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>
            
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Hợp đồng nhận việc</span>
              <p class="text-caption font-weight-bold error--text">Nhân viên đọc và kiểm tra các thông tin hợp đồng sau đó xác nhận lại các thông tin cần chỉnh sửa</p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="6" v-for="(contract, key) in contracts" :key="key" class="text-center">
                  <v-avatar tile style="cursor: pointer;" class="rounded" size="128" @click="dialog = true, dialogContractData = contract, dialogContractNote = ''">
                    <v-img contain src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"></v-img>
                  </v-avatar>
                  <small>{{ contract.contractNumber }}</small>
                  <p><b :class="statusDict[contract.status].color + '--text'">{{ statusDict[contract.status].name }}</b>
                    <a class="d-block text--primary font-weight-bold" :href="contract.fileUrl+'?ticket=' + generateRandom10DigitNumber()">Tải xuống</a>
                  </p>
                </v-col>
                <!-- <v-col cols="6">
                  <v-avatar tile class="rounded" size="128" @click="dialog = true">
                    <v-img contain src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"></v-img>
                  </v-avatar>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <h3 class="text-h5 mt-2">Thông tin cá nhân </h3>
          <v-divider class="my-3"></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Thông tin cá nhân cơ bản</span>
              <p class="text-caption text--secondary"> </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.employeeName" label="Họ & Tên"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.gender" label="Giới tính"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="formatDate(employeeData.birthday)" label="Ngày sinh"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field readonly  outlined hide-details :value="employeeData.phoneNumber" label="Số điện thoại"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field readonly  outlined hide-details :value="employeeData.emergencyPhoneNumber" label="Số điện thoại người thân" ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field readonly  outlined hide-details :value="employeeData.mail" label="Email"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field readonly  outlined hide-details :value="employeeData.maritalStatus" label="Tình trạng hôn nhân"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>
            
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Thông tin định danh</span>
              <p class="text-caption text--secondary"> </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.idNumber" label="CMND/CCCD"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.idPlace" label="Nơi cấp"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="formatDate(employeeData.idDate)" label="Ngày cấp"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>

            <!-- DIA CHI -->
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Địa chỉ thường trú</span>
              <p class="text-caption text--secondary"> </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.cityName" label="Thành phố"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.districtName" label="Quận (huyện)"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.wardName" label="Phường (xã)"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea rows="2" outlined hide-details :value="employeeData.address" label="Địa chỉ chi tiết"></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>

            <!-- DIA CHI 2 -->
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Địa chỉ tạm trú</span>
              <p class="text-caption text--secondary"> </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.dCityName" label="Thành phố"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.dDistrictName" label="Quận (huyện)"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.dWardName" label="Phường (xã)"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea rows="2" outlined hide-details :value="employeeData.domicileAddress" label="Địa chỉ chi tiết"></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>
            
            <!-- TON GIAO -->
            <v-col cols="12" md="6">
              <span class="text-subtitle-2">Tôn giáo và dân tộc</span>
              <p class="text-caption text--secondary"> </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.ethnicityName" label="Dân tộc"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.religionName" label="Tôn giáo"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hide-details :value="employeeData.originName" label="Quốc tịch"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card  flat>
          <v-card-title>Bộ phận hỗ trợ</v-card-title>
          <v-card-text>
            <v-list three-line>
              <v-list-item >
                <v-list-item-avatar>
                  <v-img src="https://s120-ava-talk.zadn.vn/3/c/7/2/10/120/7ea1a0ef793bf6f06024c3143c2f2807.jpg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Lê Văn C</v-list-item-title>
                  <v-list-item-subtitle>Hỗ trợ cập nhật thông tin nhân viên </v-list-item-subtitle>
                </v-list-item-content>
                
                <v-icon color="primary">mdi-chevron-right</v-icon>
              </v-list-item>
              
              <v-list-item >
                <v-list-item-avatar>
                  <v-img src="https://s120-ava-talk.zadn.vn/f/8/7/1/56/120/751c85c97a55550592023bf796cd2d46.jpg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Nguyễn Văn D</v-list-item-title>
                  <v-list-item-subtitle>Hỗ trợ về tuyển dụng </v-list-item-subtitle>
                </v-list-item-content>
                  <v-icon color="primary">mdi-chevron-right</v-icon>
              </v-list-item>
              
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog" @input="zoom=1"
      scrollable 
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> 
          <div style="width: 100%">Hợp đồng số {{ dialogContractData.contractNumber }}</div>
          <v-slider label="Zoom pdf" track-color="green" color="orange darken-2" class="font-weight-bold text-primary green--text text--darken-4" hint="Im a hint" max="2" min="0" v-model="zoom" :step=".25" @change="pdfRenderHandler"></v-slider>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text--primary">
          <!-- <div style="min-width: 724px;">
            <span v-html="dialogContractData.htmlSource"></span>
          </div> -->
          <vue-pdf-embed :style="'zoom:'+ zoom" ref="pdfPreview" :source="dialogContractData.fileUrl+'?ticket=' + generateRandom10DigitNumber()" :width="800"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="flex-wrap justify-center">
          <v-btn color="success" class="my-2" @click.prevent="employeeConfirm(dialogContractData.id, 3)" :disabled="dialogContractData.status!=2">Xác nhận </v-btn>
          <v-btn color="error" class="my-2" @click.prevent="employeeDeny(dialogContractData.id, 4)" :disabled="dialogContractData.status!=2">Điều chỉnh thông tin</v-btn>
          <v-col cols="12" style="flex-grow: 1;">
            <v-textarea rows="2" v-model="dialogContractNote"
              label="Thông tin cần chỉnh sửa" outlined 
              placeholder="Yêu cầu chỉnh sửa lại tên là Nguyễn Văn C, ngày sinh là 8/10/1996,v.v.v.. " persistent-placeholder>
            </v-textarea>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Api from './api.js'
import * as moment from "moment/moment"
export default {
  apiService: null,
  created() {
    this.apiService = new Api()
    this.fetchData()
  },
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      statusDict:{
        2: {name: 'Chờ xác nhận', color: 'green'},
        3: {name: 'Chờ HR duyệt', color: 'blue'},
        4: {name: 'Trả về', color: 'red'},
        1: {name: 'Hoàn tất', color: 'green'},
      },
      employeeData: {
        "departmentName": "",
        "departmentAddress": "",
        "positionName": "",
        "positionStateName": "",
        "hireDate": "",
        "employeeCode": "",
        "employeeName": "",
        "gender": "",
        "birthday": "",
        "phoneNumber": "",
        "emergencyPhoneNumber": "",
        "mail": "",
        "maritalStatus": "",
        "idNumber": "",
        "idPlace": "",
        "idDate": "",
        "cityName": "",
        "districtName": "",
        "wardName": "",
        "address": "",
        "dCityName": "",
        "dDistrictName": "",
        "dWardName": "",
        "domicileAddress": "",
        "ethnicityName": "",
        "religionName": "",
        "originName": "",
        "contractv2s": []
      },
      zoom: 1,
      dialog: false,
      dialogContractData: {},
      dialogContractNote: "",
      contracts:[],
      contractNumber: "",
      htmlData: null
      
    }
  },
  methods: {
    
    generateRandom10DigitNumber() {
        const min = 1000000000; // Smallest 10-digit number (10 zeros)
        const max = 9999999999; // Largest 10-digit number (nine nines)

        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    pdfRenderHandler() {
      // this.$refs.pdfPreview.render();
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },

    fetchData() {
      this.apiService.pendingUser().then(d => {
        console.log(d)
        this.employeeData = Object.assign({}, d.data)
        this.contracts = d.contracts
      })
    },

    employeeConfirm(id, status) {
      return this.apiService.pendingUserStatus(id, status, "").then(() => {
        this.fetchData()
      })
    },
    
    employeeDeny(id, status) {
      return this.apiService.pendingUserStatus(id, status, this.dialogContractNote).then(() => {
        this.fetchData()
      })
    }
  }
}
</script>

<style scoped>
.hero {
  height: 200px;
  background-image: url('https://img.freepik.com/free-photo/flat-lay-stationary-arrangement-desk-with-copy-space_23-2148404487.jpg?w=826&t=st=1690965649~exp=1690966249~hmac=727db0cea6a81bd184f7cc335e588c47c532362c4c311caba6436cdf54d5ff9e');
  background-size: 100%;
}
</style>