<template>
<v-card height="calc(100vh - 336px)" flat>
    <v-card-title>
        <v-spacer></v-spacer>
        Xin vui lòng đợi redirect to : {{redirectToURL}}
        <v-spacer></v-spacer>
        
    </v-card-title>
    <v-card-text class="text-center">
        <v-progress-circular class="my-12"
        :size="200"
        :width="7"
        color="orange"
        indeterminate
        ></v-progress-circular>
    </v-card-text>
</v-card>
</template>

<script>
import http from '@/components/services/http-common';
export default {
    props: ['id'],
    data(){
        return {
            redirected: false,
            redirectToURL: ""
        }
    },
    methods:{
        redirectTo() {
            if(!this.redirected){
                let url = `qrLinks/redirect/${this.id}`;
                    return http.get(url).then(res=>{
                        this.redirectToURL = res.data;
                        if(!this.redirectToURL.includes("http"))
                        {
                            this.redirectToURL = "http://"+this.redirectToURL;
                        }
                        window.location.href = this.redirectToURL;
                        this.redirected =true;
                })
            }
        }
    },
    mounted(){
        this.redirectTo();
    }
}
</script>
